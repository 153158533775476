import { FC } from 'react';

import { News } from '@common/clients/api';
import { fetchMostReadNews } from '@web/handlers/fetchMostReadNews';
import { NewsBlockItem, NewsBlockItemProps } from '@web/molecules/NewsBlockItem';
import { ProTag } from '@web/molecules/ProTag';
import { WidgetType } from '@web/organisms/Sidebar/types';
import { SimpleWidget } from '@web/organisms/SimpleWidget';
import { SimpleWidgetConfig } from '@web/organisms/SimpleWidget/types';

import { FetcherProps } from './types';
import { mapFetchedData } from './utils';

interface Props {
    dataList?: NewsBlockItemProps[];
}

export const widgetConfig: SimpleWidgetConfig = {
    title: 'pro.title',
    metaKey: WidgetType.PRO_NEWS,
    icon: <ProTag />,
};

export const Widget: FC<Props> = ({ dataList }) => {
    return <SimpleWidget minimalUI {...widgetConfig} ComponentItem={NewsBlockItem} dataList={dataList} />;
};

export const fetcherProps: FetcherProps<News[], NewsBlockItemProps[]> = {
    fetcher: fetchMostReadNews({ functionality: 'pro' }),
    mapper: mapFetchedData('visitCount', true),
    storageKey: WidgetType.PRO_NEWS,
};
