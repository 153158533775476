import { Logo } from '@common/atoms/Logo';

import styles from './ProTag.module.scss';

export const ProTag = ({ tag, isInverted }: { tag?: string; isInverted?: boolean }) => (
    <span className={styles.ProTag}>
        {isInverted ? <Logo.proInverse /> : <Logo.pro />}
        {tag ? <span className={styles['pro-text']}>{tag}</span> : null}
    </span>
);
